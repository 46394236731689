// ambassador
export const SET_AMBASSADOR = "SET_AMBASSADOR";

export const TOGGLE_SELECT_TC = "TOGGLE_SELECT_TC";
export const TC_FAIL = "TC_FAIL";

// userProfile
export const SET_PROFILE = "SET_PROFILE";
// export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_AMBASSADOR_FETCHING = "SET_AMBASSADOR_FETCHING";
