import { endpoints } from "../../../config/api";

const isLoggedIn = () => {
  const refToken = localStorage.getItem("refreshToken");
  console.log(refToken);
  return !!refToken;
};

interface IProps {
  children: any;
}

const PrivateRoute = ({ children }: IProps) => {
  if (isLoggedIn()) {
    return children;
  }

  window.location.href = `${
    endpoints.AuthUrl
  }/auth/login?redirect_to=${encodeURIComponent(window.location.href)}`;
  return null;
};

export default PrivateRoute;
