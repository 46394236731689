import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-page">
      <img
        className="not-found-image"
        alt="404-not-found"
        src={require("../../assets/img/404_not_found.svg")}
      />

      <div className="nf-text">
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default NotFound;
