import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Sidebar from '../components/common/Sidebar/Sidebar'
import Navbar from '../components/common/Navbar/Navbar'

import PrivateRoute from '../components/common/PrivateRoute/PrivateRoute'
import CubeSpinner from '../components/common/Loaders/CubeSpinner'
import http from '../config/http'
import {
  setProfile /*, saveReferralCode*/,
} from '../store/Actions/userprofileActions'
// import { setAmbassador, setAmbassadorFetching } from "../store/Actions/ambassadorActions";
// import { setAmbassador } from "../store/Actions/ambassadorActions";

const Home = lazy(() => import('./Home/Home'))
const UserProfile = lazy(() => import('./UserProfile/UserProfile'))

const Base = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // setAmbassadorFetching(dispatch, true);
    http
      .getWithRefresh('/Profile')
      .then((response: any) => {
        if (response) {
          const {
            id,
            name,
            email,
            role,
            picture,
            qrCodeUrl,
            institutionId,
            gender,
            mobileNumber,
            category,
            referrerAmbassadorId,
            ambassador,
            isPaid,
          } = response

          const ambassadorId = !!ambassador ? ambassador.id : 0
          const profile = {
            id,
            name,
            email,
            role,
            picture,
            qrCodeUrl,
            institutionId,
            gender,
            mobileNumber,
            category,
            referrerAmbassadorId,
            ambassadorId,
            isPaid,
          }
          // console.log('profile: ', profile);

          setProfile(dispatch, profile)
        }

        // if (ambassadorId) {
        //   http.getWithRefresh("/Ambassador").then((response: any) => {
        //     // console.log('/ambassador: ', response);
        //     setAmbassador(dispatch, response);
        //     setAmbassadorFetching(dispatch, false);
        //   }).catch((e) => {
        //     console.log('error: ', e);
        //     setAmbassadorFetching(dispatch, false);
        //   });
        // }
      })
      .catch((e) => {
        console.log('error: ', e)
        // setAmbassadorFetching(dispatch, false);
      })
  }, [dispatch])

  // useEffect(() => {
  //   try {
  //     const referralCode = Number(localStorage.getItem("referralCode"));
  //     if (referralCode)
  //       saveReferralCode(referralCode);
  //   } catch (e) {
  //     console.log('An error occurred...', e);
  //   }
  // }, []);

  return (
    <PrivateRoute>
      <div className='App'>
        <div className='wrapper '>
          <Sidebar />
          <div className='main-panel'>
            <Navbar />
            <div className='content'>
              <div className='container-fluid'>
                <Suspense fallback={<CubeSpinner />}>
                  <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/profile' component={UserProfile} />
                    <Route
                      path='/complete-profile'
                      render={(props) => (
                        <UserProfile
                          {...props}
                          completeProfileToContinue={true}
                        />
                      )}
                    />
                    {/* <Route
                      path="/ambassador-dashboard"
                      component={CampusAmbassadorPage}
                    /> */}
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivateRoute>
  )
}

export default Base
