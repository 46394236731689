import { combineReducers } from "redux";

import ambassadorReducer from "./ambassadorReducer";
import joinProgramReducer from "./joinProgramReducer";
import userprofileReducer from "./userprofileReducer";

const rootReducer = combineReducers({
  ambassador: ambassadorReducer,
  joinProgram: joinProgramReducer,
  userProfile: userprofileReducer,
});

export default rootReducer;
