import { endpoints } from './api'

interface postData {
  [key: string]: any
}

interface IRefresh {
  accessToken: string
  refreshToken: string
}

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  // console.log('previous refresh token: ', refreshToken)
  const headers: HeadersInit = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  return fetch(endpoints['ApiRoot'] + '/auth/refresh', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      refreshToken,
    }),
  })
    .then((res) => res.json())
    .then(async ({ accessToken, refreshToken }: IRefresh) => {
      // console.log({ accessToken, refreshToken })
      localStorage.setItem('refreshToken', refreshToken)
      // localStorage.setItem('jwt_token', accessToken)
      storeJwtInCookie(accessToken)
      return accessToken
    })
}

const storeJwtInCookie = (accessToken: string): void => {
  const d = new Date()
  d.setTime(d.getTime() + 13 * 60 * 1000) // cookie expires in 13 minutes from now.
  const expires = 'expires=' + d.toUTCString()
  // document.cookie = 'token=' + accessToken
  document.cookie = 'token=' + accessToken + ';' + expires + ';path=/'
  // document.cookie = 'expires=' + expires
  // const prevCookie = document.cookie
  // document.cookie = prevCookie + '; token=' + accessToken + '; expires=' + expires
}

export const getJwtFromCookie = (): string => {
  const cookies = document.cookie.split('; ')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (name === 'token') {
      const token = cookie.substr(eqPos + 1)
      return token
    }
  }
  return ''
}

const post = async (
  url: string,
  data: postData,
  endpoint: 'ApiRoot' | 'EventsApiRoot' = 'ApiRoot',
  token: string
) => {
  const headers: HeadersInit = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  if (token) {
    headers.append('Authorization', 'Bearer ' + token)
  }
  return fetch(endpoints[endpoint] + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
}

const postWithRefresh = async (
  url: string,
  data: postData,
  endpoint: 'ApiRoot' | 'EventsApiRoot' = 'ApiRoot'
) => {
  // const jwt_token = localStorage.getItem('jwt_token')
  const jwt_token = getJwtFromCookie()
  // console.log(jwt_token)
  // if (!jwt_token) return
  return post(url, data, endpoint, jwt_token)
    .then((res) => res.json())
    .catch(async (error) => {
      console.log('error: ', error)
      return refreshAccessToken()
        .then(async (accessToken) => {
          return post(url, data, endpoint, accessToken)
            .then((res) => res.json())
            .catch((error) => console.log('error occurred in POST...', error))
        })
        .catch((error) => {
          console.log('error refreshing token...', error)
        })
    })
}

const get = async (
  url: string,
  endpoint: 'ApiRoot' | 'EventsApiRoot' = 'ApiRoot',
  token: string
) => {
  const headers: HeadersInit = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  if (token) {
    headers.append('Authorization', 'Bearer ' + token)
  }
  return fetch(endpoints[endpoint] + url, {
    method: 'GET',
    headers: headers,
  })
}

const getWithRefresh = async (
  url: string,
  endpoint: 'ApiRoot' | 'EventsApiRoot' = 'ApiRoot'
) => {
  // const jwt_token = localStorage.getItem('jwt_token')
  const jwt_token = getJwtFromCookie()
  // if (!jwt_token) return
  return get(url, endpoint, jwt_token)
    .then((res) => res.json())
    .catch(async (error) => {
      console.log('error: ', error)
      return refreshAccessToken()
        .then(async (accessToken) => {
          return get(url, endpoint, accessToken)
            .then((res) => res.json())
            .catch((error) => {
              console.log('error occurred in GET...', error)
            })
        })
        .catch((error) => {
          console.log('error refreshing token...', error)
        })
    })
}

const uploadImage = (url: string, data: any) => {
  const headers: HeadersInit = new Headers()
  // const token = localStorage.getItem('jwt_token')
  const token = getJwtFromCookie()

  if (token) {
    headers.append('Authorization', 'Bearer ' + token)
  }
  return fetch(endpoints['ApiRoot'] + url, {
    method: 'POST',
    headers,
    body: data,
  })
}

const uploadImageWithRefresh = async (url: string, data: any) => {
  return uploadImage(url, data).catch(async (error) => {
    console.log('error: ', error)
    return refreshAccessToken()
      .then(async (accessToken) => {
        // console.log({ accessToken, refreshToken })
        // localStorage.setItem('refreshToken', refreshToken)
        // localStorage.setItem('jwt_token', accessToken)
        // storeJwtInCookie(accessToken)
        return uploadImage(url, data)
      })
      .then((res) => res.json())
  })
}

export default { postWithRefresh, getWithRefresh, uploadImageWithRefresh }
