import { SET_PROFILE } from '../constants'
import http from '../../config/http'
import { IUpdateProfile } from '../../pages/UserProfile/profileTypes'

interface IProfile {
  [key: string]: any
}

export const setProfile = (dispatch: any, profile: IProfile) => {
  dispatch({
    type: SET_PROFILE,
    payload: profile,
  })
}

export const saveProfile = (dispatch: any, profile: IUpdateProfile) => {
  return new Promise((resolve, reject) => {
    http
      .postWithRefresh('/Profile/update', profile)
      .then((res) => {
        // console.log("res: ", res);
        setProfile(dispatch, profile);
        resolve(res);
      })
      .catch((e) => {
        console.log('Error: ', e);
        reject(e);
      })
  })
}

export const updateProfilePic = (dispatch: any, selectedImage: any) => {
  let data = new FormData()
  data.append('Image', selectedImage)
  http
    .uploadImageWithRefresh('/Profile/update/image', data)
    .then((res) => {
      // console.log("res: ", console.log(res));
      setProfile(dispatch, { picture: URL.createObjectURL(selectedImage) })
    })
    .catch((e) => {
      console.log('Error: ', e)
    })
}

export const saveReferralCode = (referralCode: number) =>
  http
    .postWithRefresh('/Ambassador/referral', { referralCode })
    .then((res) => console.log('referred res: ', res))
    .catch((e) => console.log('error: ', e))
