import { SET_AMBASSADOR, SET_AMBASSADOR_FETCHING } from "../constants";

export interface AmbassadorState {
  ambassadorId: number;
  freeMembership: number;
  paidMembership: number;
  isAmbassadorFetching: boolean;
}

const initialState: AmbassadorState = {
  ambassadorId: 0,
  freeMembership: 0,
  paidMembership: 0,
  isAmbassadorFetching: false,
};

const ambassadorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_AMBASSADOR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_AMBASSADOR_FETCHING:
      return {
        ...state,
        isAmbassadorFetching: action.isFetching,
      };
    default:
      return state;
  }
};

export default ambassadorReducer;
