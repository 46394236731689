if (!process.env.REACT_APP_ACC_BACKEND_HOSTNAME) {
  throw new Error('REACT_APP_ACC_BACKEND_HOSTNAME is not set')
}
if (!process.env.REACT_APP_EVENTS_BACKEND_HOSTNAME) {
  throw new Error('REACT_APP_ACC_BACKEND_HOSTNAME is not set')
}
if (!process.env.REACT_APP_AUTH_HOSTNAME) {
  throw new Error('REACT_APP_AUTH_BASE_URL is not set')
}

const accountsHostname = process.env.REACT_APP_ACC_BACKEND_HOSTNAME;
const eventsHostname = process.env.REACT_APP_EVENTS_BACKEND_HOSTNAME;
const authHostName = process.env.REACT_APP_AUTH_HOSTNAME;

export const endpoints = {
  ApiRoot: `https://${accountsHostname}/api`,
  EventsApiRoot: `https://${eventsHostname}/api`,
  AuthUrl: `https://${authHostName}`,
}
