import React, { useEffect } from "react";
import "./Logout.css";

export default function Logout() {
  useEffect(() => {
    const currUrl = new URL(window.location.href);
    const redirectUrl = decodeURIComponent(
      currUrl.searchParams.get("redirect_to") || "/"
    );

    console.log("Redirecting to", redirectUrl);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 1000);
  }, []);

  return (
    <div className='logout-wrapper'>
      <h2>Logging out...</h2>
    </div>
  );
}
