import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./Navbar.scss";
import { routes } from "../Sidebar/routes";
import {
  selectUsername,
  selectUserProfilePic,
} from "../../../store/Selectors/userprofileSelectors";

const Navbar = () => {
  const route = routes.find((x) => window.location.pathname === x.path);
  const { name, picture } = useSelector((state: any) => ({
    name: selectUsername(state),
    picture: selectUserProfilePic(state),
  }));

  return (
    <nav className='navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top'>
      <div className='container-fluid'>
        <div className='navbar-wrapper'>
          <Link className='navbar-brand' to={route?.path || ""}>
            {route?.name}
          </Link>
        </div>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          aria-controls='navigation-index'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='sr-only'>Toggle navigation</span>
          <span className='navbar-toggler-icon icon-bar'></span>
          <span className='navbar-toggler-icon icon-bar'></span>
          <span className='navbar-toggler-icon icon-bar'></span>
        </button>
        <div className='collapse navbar-collapse justify-content-end'>
          <ul className='navbar-nav'>
            <li className='nav-item px-3'>
              {/* <a
                className='nav-link navbar-profile'
                href='#'
                // id='navbarDropdownMenuLink'
                // data-toggle='dropdown'
              > */}
                <p className='d-inline pr-3 profile-name'>{!!name ? name : "User"}</p>
                {!!picture ? (
                  <img className="profile-img" src={picture} alt='profile' referrerPolicy="no-referrer" />
                ) : (
                  <i className='material-icons'>account_circle</i>
                )}
              {/* </a> */}
              {/* <div
                className='dropdown-menu dropdown-menu-right nb-text-container'
                aria-labelledby='navbarDropdownMenuLink'
              > */}
                {/* <button
                  className='logout-btn nb-text'
                  onClick={() => {
                    console.log("Hello")
                    localStorage.removeItem("refreshToken");
                    window.location.href = `https://${process.env.REACT_APP_AUTH_HOSTNAME}/auth/logout?redirect_to=${window.location.origin}`;
                  }}
                >
                  Logout
                </button> */}
              {/* </div> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
