import { createSelector } from 'reselect';

export const selectUserprofile = (state:any) => state.userProfile;

export const selectUsername = createSelector(
    [selectUserprofile],
    profile => profile.name,
);

export const selectUserProfilePic = createSelector(
    [selectUserprofile],
    profile => profile.picture,
);