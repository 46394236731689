import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { routes } from "./routes";

const Sidebar = () => {
  const location = useLocation();
  const isActive = (pathname: string) => location.pathname === pathname;

  return (
    <div
      className="sidebar"
      data-color="green"
      data-background-color="black"
      data-image="./assets/img/sidebar-1.jpg"
    >
      <div className="logo">
        <NavLink to="/" className="simple-text logo-normal">
          <img
            src={"../../../assets/img/logo.png"}
            alt="logo"
            className="logo-img"
          />
          <br></br>
          <br></br>
          <span>Excel Accounts</span>
        </NavLink>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {routes.map((route, i) => (
            <li
              className={`nav-item ${isActive(route.path) ? "active" : ""}`}
              key={i}
            >
              <NavLink className="nav-link" to={route.path}>
                <i className="material-icons">{route.icon}</i>
                <p>{route.name}</p>
              </NavLink>
            </li>
          ))}
          <br/>
          <li className="nav-item">
            <div
                className='nav-link btn btn-outline-success nb-text-container'
                onClick={() => {
                  console.log("Hello")
                  localStorage.removeItem("refreshToken");
                  window.location.href = `https://${process.env.REACT_APP_AUTH_HOSTNAME}/auth/logout?redirect_to=${window.location.origin}`;
                }}
              >
                  Logout
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
