import React from "react";
import "./CubeSpinner.scss";

const CubeSpinner = () => {
  return (
    <div className="spinner">
      <div className="cube1" />
      <div className="cube2" />
    </div>
  );
};

export default CubeSpinner;
