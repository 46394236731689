export const routes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: 'dashboard',
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'person',
  },
  // {
  //   path: "/ambassador-dashboard",
  //   name: "Campus Ambassador",
  //   icon: "flag",
  // },
]
