import { SET_PROFILE } from "../constants";

interface IProfile {
  id: number;
  name: string;
  email: string;
  role: string;
  picture: string;
  qrCodeUrl: string;
  institutionId: number | null;
  institutionName: string;
  gender: string;
  mobileNumber: string;
  category: string;
  referrerAmbassadorId: number;
  ambassadorId: number;
  isPaid: number;
}

const defaultState: IProfile = {
  id: 0,
  name: "",
  email: "",
  role: "",
  picture: "",
  qrCodeUrl: "",
  institutionName: "",
  gender: "",
  mobileNumber: "",
  category: "",
  institutionId: null,
  ambassadorId: 0,
  referrerAmbassadorId: 0,
  isPaid: 0,
};

const userprofileReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userprofileReducer;
