import { createStore, compose } from "redux";
import rootReducer from "./Reducers/rootReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const INITIAL_STATE = {};

const store = createStore(rootReducer, INITIAL_STATE, composeEnhancers());

export default store;
