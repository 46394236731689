import { TOGGLE_SELECT_TC, TC_FAIL } from "../constants";

export interface JoinProgramState {
  selected: boolean;
  errorMsg: string;
}

const initialState: JoinProgramState = {
  selected: false,
  errorMsg: "",
};

const joinProgramReducer = (state = initialState, action: any) => {
  const errorMsg: string = action.payload || "";

  switch (action.type) {
    case TOGGLE_SELECT_TC:
      return {
        ...state,
        selected: !state.selected,
      };
    case TC_FAIL:
      return {
        ...state,
        errorMsg,
      };
    default:
      return state;
  }
};

export default joinProgramReducer;
